<template>
    <div class="photo-modal__container">
        <div class="photo-modal__img-wrapper">
            <img class="photo-modal__img" :src="image" :alt="text">
        </div>
        <div class="photo-modal__text">{{ text }}</div>
    </div>
</template>

<script>
    export default {
        props: ['image','text']
    }
</script>

<style lang="sass">
    @import "#sass/v-style"

    .v--modal-overlay[data-modal="photo-modal"]
        background: rgba(30, 30, 30, .9)
        .v--modal-background-click
            display: flex
            align-items: center
            justify-content: center
    .photo-modal
        top: 0 !important
        left: 0 !important
        width: auto !important
        height: auto !important
        max-width: 1024px
        padding: 40px 0
        &__wrapper
            position: relative
            display: block
        &__img
            display: block
            max-width: 100%
            max-height: calc(100vh - 80px)
            height: auto
            margin: 0 auto
            user-select: none
        &__text
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            padding: 8px 16px
            font-size: 16px
            color: #ffffff
            text-align: center
            pointer-events: none
</style>
